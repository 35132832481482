<template>
  <div class="major">
            <div class="top-box">
              <div class="top-title">专业信息查询</div>
              <div class="btn-box">
                <div class="btn" @click="handleSearchClick"><i class="el-icon-search"></i> 查询</div>
                <div class="btn" @click="handlePushClick"><i class="el-icon-plus"></i> 推送</div>
              </div>
            </div>
            <el-row class="form-group">
              <el-col :span="4" class="form-item">
                  <div class="label">专业名称</div>
                  <div class="input-box">
                    <el-input v-model="majorName" placeholder="请输入专业名称" class="form-control"></el-input>
                  </div>
              </el-col>
              <el-col :span="4" class="form-item">
                  <div class="label">学科门类</div>
                  <div class="input-box">
                    <el-select class="form-control" v-model="subjectType" 
                    v-loading="subjectTypeListLoading"
                    element-loading-spinner="el-icon-loading">
                      <el-option key="0" label="请选择..." value=""></el-option>
                      <el-option v-for="item in subjectTypeList" 
                      :key="item.key" 
                      :label="item.label" 
                      :value="item.value" 
                      ></el-option>
                    </el-select>
                  </div>
              </el-col>
              <el-col :span="4" class="form-item">
                  <div class="label">专业门类</div>
                  <div class="input-box">
                    <el-select class="form-control" v-model="majorType" 
                    v-loading="majorTypeListLoading"
                    element-loading-spinner="el-icon-loading">
                      <el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in majorTypeList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
              </el-col>
            </el-row>
            <!--  -->
            <div class="table-container-body">
              <el-table ref="majorTable" height="500" :data="tableData" stripe class="table-body" 
              :header-cell-style="{background:'#e6e6e6', color: '#646464'}"
              v-loading="majorTableLoading">
                <el-table-column fixed type="selection" width="42">
                </el-table-column>
                <el-table-column prop="majorName" label="专业名称">
                </el-table-column>
                <el-table-column prop="subjectType" label="学科门类">
                </el-table-column>
                <el-table-column prop="majorType" label="专业门类" width="100">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                  <template slot-scope="scope">
                    <div class="table-btn-box">
                      <div class="table-btn view" @click="handleViewClick(scope.row)">
                        查看
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="table-container-footer">
              <el-pagination
                layout="total, prev, pager, next, sizes, jumper"
                :page-sizes="[10, 50, 100, 500]"
                :page-size="pageSize"
                :total="tableTotal"
                :current-page="pageNo"
                @current-change="pageChange"
                @size-change="sizeChange"
              >
              </el-pagination>
            </div>
            <el-dialog
              v-loading="viewLoading"
              :visible.sync="dialogVisible"
              top="5vh"
              width="70%">
              <div class="major-dialog-body">
                <div class="major-info-container">
                  <div class="container-title">
                    <div class="title-text">{{majorInfo.majorName}}</div>
                  </div>
                  <div class="container-body">
                    <div class="card-container">
                      <div class="card-body">
                        <table class="card-table">
                          <tr>
                              <td class="title">专业代码</td>
                              <td class="info">{{majorInfo.subjectCode}}</td>
                          </tr>
                          <tr>
                              <td class="title">所诉学科</td>
                              <td class="info">{{majorInfo.courseType}}</td>
                          </tr>
                          <tr>
                              <td class="title">相近专业</td>
                              <td class="info">{{majorInfo.similarSubject}}</td>
                          </tr>
                          <tr>
                              <td class="title">新高考学科要求</td>
                              <td class="info">{{majorInfo.collegeExamRequired}}</td>
                          </tr>
                        </table>
                      </div>
                      <div class="card-body">
                        <table class="card-table">
                          <tr>
                              <td class="title">职业兴趣匹配</td>
                              <td class="info">{{majorInfo.professionMatching}}</td>
                          </tr>
                          <tr>
                              <td class="title">男女比例</td>
                              <td class="info">{{majorInfo.genderRatio}}</td>
                          </tr>
                          <tr>
                              <td class="title">性别要求</td>
                              <td class="info">{{majorInfo.genderRequired}}</td>
                          </tr>
                          <tr>
                              <td class="title">薪资水平</td>
                              <td class="info">{{majorInfo.salary}}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <el-divider></el-divider>
                    <div class="desc-container">
                      <div class="desc-title">
                        <div class="desc-text">专业介绍</div>
                      </div>
                      <p>{{majorInfo.majorIntroduction}}</p>
                    </div>
                    <el-divider></el-divider>
                    <div class="desc-container">
                      <div class="desc-title">
                        <div class="desc-text">开设课程</div>
                      </div>
                      <p>{{majorInfo.offerCourses}}</p>
                    </div>
                    <el-divider></el-divider>
                    <div class="desc-container">
                      <div class="desc-title">
                        <div class="desc-text">就业状况</div>
                      </div>
                      <p>{{majorInfo.employmentStatus}}</p>
                    </div>
                    <el-divider></el-divider>
                    <div class="desc-container">
                      <div class="desc-title">
                        <div class="desc-text">专家评语</div>
                      </div>
                      <p>{{majorInfo.expertComment}}</p>
                    </div>
                  </div>
                </div>
                <div class="recommend-container">
                  <div class="container-title">
                    <div class="title-text">推荐大学</div>
                  </div>
                  <div class="container-body">
                    <div class="school-container" v-for="item in schoolList"
                      :key="item.id">
                      <img class="school-pic" :src="item.pic"/>
                      <div class="school-info">
                        <div class="name-container">{{item.schoolName}}</div>
                        <div class="label-container">
                          <div class="label" v-for="label in item.label"
                            :key="label.title"
                            :style="{'color':label.color, 'border-color':label.color}">
                            {{label.title}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">关 闭</el-button>
              </span>
            </el-dialog>
            <!--  -->
  </div>
</template>
<script>
export default {
  name: "major",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      pageNo: 1,
      pageSize:10,
      tableTotal:0,
      dialogVisible:false,
      subjectTypeListLoading:false,
      majorTypeListLoading:false,
      majorTableLoading:false,
      viewLoading:false,
      majorName:"",
      subjectType:"",
      majorType:"",
      subjectTypeList:[],
      majorTypeList:[],
      schoolList:[],
      tableData: [],
      majorInfo:{
        majorName:"",
        subjectCode:"",
        courseType:"",
        similarSubject:"",
        collegeExamRequired:"",
        professionMatching:"",
        genderRatio:"",
        genderRequired:"",
        salary:"",
        majorIntroduction:"",
        offerCourses:"",
        employmentStatus:"",
        expertComment:""
      }
    };
  },
  methods: {
    getSubjectList(){
      this.subjectTypeListLoading = true;
      this.$http.post(this.$apis.getDictionary+"?type=course").then(r=>{
        this.subjectTypeListLoading = false;
        if(r.data){
          this.subjectTypeList = r.data.map(m=>{
            return {
              key:m.id,
              label:m.name,
              value:m.code
            }
          });
        }
      });
    },
    getMajorList(){
      this.majorTypeListLoading = true;
      this.$http.post(this.$apis.getDictionary+"?type=subject_type").then(r=>{
        this.majorTypeListLoading = false;
        if(r.data){
          this.majorTypeList = r.data.map(m=>{
            return {
              key:m.id,
              label:m.name,
              value:m.code
            }
          });
        }
      });
    },
    initTableData(){
      this.majorTableLoading = true;
      var data ={
          courseType: this.subjectType,
          currentPage: this.pageNo,
          pageSize: this.pageSize,
          subjectName: this.majorName,
          subjectType: this.majorType
      };
      this.$http.post(this.$apis.getMajorByPage,data).then(r=>{
        this.majorTableLoading = false;
        if(r.data && r.data.list){
          this.tableData = r.data.list.map(m=>{
            return {
              id:m.id,
              majorName: m.subjectName,
              subjectType: m.courseType,
              majorType: m.subjectType,
            }
          });
          this.tableTotal = r.data.total;
        }
      });
    },
    getRanbowColor(){
      let colors= ["#da564f","#fdda4c","#b2e175","#bf53db","f98ac4"];
      let ranbow = Math.floor(Math.random()*4);
      return colors[ranbow];
    },
    handleViewClick(row) {
      this.viewLoading = true;
      this.$http.post(this.$apis.getMajorDetail+"?id="+row.id).then(r=>{
        this.viewLoading = false;
        if(r.data){
          this.majorInfo.majorName = r.data.subjectName;
          this.majorInfo.subjectCode = r.data.subjectCode;
          this.majorInfo.courseType = r.data.courseType;
          this.majorInfo.similarSubject = r.data.similarSubject;
          this.majorInfo.collegeExamRequired = r.data.collegeExamRequired;
          this.majorInfo.professionMatching = r.data.professionMatching;
          this.majorInfo.genderRatio = r.data.genderRatio;
          this.majorInfo.genderRequired = r.data.genderRequired;
          this.majorInfo.salary = r.data.salary;
          this.majorInfo.majorIntroduction = r.data.summary;
          this.majorInfo.offerCourses = r.data.course;
          this.majorInfo.employmentStatus = r.data.employmentSituation;
          this.majorInfo.expertComment = r.data.expertReviews;

          if(r.data.schools){
            this.schoolList = r.data.schools.map(m=>{
              return{
                id:m.schoolId,
                schoolName:m.schoolName,
                pic:m.pic,
                label: m.labels.map(lm=>{
                  return {
                    title:lm.lName,
                    color:lm.lColor
                  }
                })
              }
            });
          }
         
        }
      });
      this.dialogVisible = true;
    },
    handleSearchClick(){
      this.pageNo = 1;
      this.initTableData();
    },
    handlePushClick(){
      if(this.$refs.majorTable.selection.length <=0){
        this.$message.warning('请先选择需要推送的数据');
        return ;
      }
      //todo
      console.log("具体业务逻辑");
    },
    pageChange(val) {
      this.pageNo = val;
      this.initTableData();
    },
    sizeChange(val){
      this.pageSize = val;
      this.initTableData();
    }
  },
  mounted(){
    this.getSubjectList();
    this.getMajorList();
    this.initTableData();
  }
};
</script>

<style lang="less" scoped>
.major {
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border-radius: 5px;
  border: 1px solid rgba(230, 230, 230, 100);

  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    border-bottom: 1px solid rgba(230, 230, 230, 100);

    .top-title {
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
      font-weight: 700;
      padding-left: 20px;
      position: relative;
    }
    .top-title::after {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      height: 27px;
      width: 3px;
      background-color: #ff450b;
    }

    .btn-box {
      display: flex;
      align-items: center;
      .btn {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }

  .form-group{
      padding: 0px 20px;
    .form-item {
      display: flex;
      align-items: center;
      padding: 15px 0;
      .label {
        display: flex;
        justify-content: flex-end;
        color: rgba(100, 100, 100, 100);
        font-size: 14px;
        width: 70px;
        padding-right:10px;
      }
      .input-box {
          display: flex;
          width: calc(100% - 100px);
      }
      .required-label{
            color: rgb(255, 0, 0);
      }
      .action-link{
          font-size: 16px;
          cursor:pointer;
          color: #ff450b;
          padding-right: 20px;
      }
    }
  }
  .form-control{
      width: 100%;
  }

  .table-container-body {
    padding: 0 20px;
    .table-body{
      width: 100%;
      overflow: auto;
      border: 1px solid #e6e6e6;
    }
    .table-btn-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .table-btn {
        cursor: pointer;
        width: 60px;
        line-height: 28px;
        border-radius: 14px;
        color: rgba(121, 160, 241, 100);
        font-size: 12px;
        text-align: center;
        border: 1px solid rgba(121, 160, 241, 100);
      }
    }
  }
  .table-container-footer{
    padding: 20px;
  }
  
  .major-dialog-body{
    background: #f4f4f4;
    width: 100%;
    min-height: 600px;
    display: flex;
    padding-top: 20px;
    .major-info-container{
      background: #fff;
      width: 70%;
    }
    .recommend-container{
      background: #fff;
      width: 30%;
      margin-left: 15px;
    }
    .container-title{
      height: 40px;
      width: 100%;
      background: #e6e6e6;
      position: relative;
      .title-text{
        font-size:14px;
        font-weight: 700;
        position: absolute;
        color: #000; 
        border-width:0 0 3px 0;
        border-style:solid;
        border-color:rgb(255, 69, 11);
        left: 20px;
        bottom: 4px;
      }
    }
    .container-body{
      padding: 20px;
      max-height: 800px;
      overflow: auto;
      .card-container{
        padding:0 10px;
        display: flex;
        justify-content: space-between;
      }
      .card-body{
        width:400px;
        border-width:17px 0 0 0;
        border-style:solid;
        border-color:#cccccc;
        border-radius: 1.2em 1.2em 0 0;
        .card-table{
          width: 100%;
          border-collapse: collapse;
          tr th, tr td { 
            padding: 5px;
            border:1px solid #cccccc; 
          }
          .title{
            font-size: 12px;
          }
          .info{
            width: 60%;
            font-size: 12px;
            color: rgb(255, 69, 11);
            text-decoration:underline;
          }
        }
      }
      .desc-container{
        .desc-title{
          width: 100%;
          .desc-text{
            font-size:14px;
            font-weight: 700;
            color: rgb(255, 69, 11); 
            border-width:0 0 0 3px;
            border-style:solid;
            border-color:rgb(255, 69, 11);
            padding-left: 10px;
          }
        }
        p{
          padding-top: 10px;
          font-size: 12px;
          text-indent: 2em;
        }
      }
      .school-container{
        border: #cccccc solid;
        border-width: 1px 0 0 0;
        display: flex;
        height: 80px;
        padding: 5px 0;
        .school-pic{
          width: 90px;
          margin:0 14px;
        }
        .school-info{ 
          width: 100%;
          position: relative;
          .name-container{
            font-size: 15px;
            padding-top:15px;
            font-weight: bold;
            color: rgb(255, 69, 11);
          }
          .label-container{
            position:absolute;
            bottom: 5px;
            display: flex;
          }
          .label{
            display: inline-block;
            border: 1px solid #646464;
            font-size: 12px;
            margin-right: 2px;
            border-radius: 4px;
            padding: 0 5px;
            line-height: 14px;
          }
        }
      }
    }
    
  }
}

</style>